import React, { useState, useEffect } from 'react';
import SimpleTable from 'modules/Tables/SimpleTable';
import AuthDownloadLink from '@hubins/components/AuthDownloadLink';
import Button from '@hubins/components/Button';
import __ from 'localisation';
import middleman from 'helpers/middleman';

const FilesTable = ({ downloadUrl, files: propsFiles, deleteUrl }) => {


  const [files, setFiles] = useState(propsFiles || []);

  useEffect(() => {
    setFiles(propsFiles || []);
  }, [propsFiles]);

  const headers = [
    {
      Header: '',
      accessor: 'downloadLink',
      width: 10,
    },
    {
      Header: '',
      accessor: 'name'
    },
    ...deleteUrl ? [{
      Header: '',
      accessor: 'deleteLink',
      width: 10,
    }] : []
  ]

  const handleDelete = async (uuid) => {
    if (window.confirm(__('are_you_sure_you_want_to_delete_file'))) {
      middleman.promiseDelete(`${deleteUrl}/${uuid}`);
      setFiles(files.filter(file => file.uuid !== uuid));
    }
  }

  return (
    <SimpleTable
      headers={headers}
      data={files.map(file => ({
        downloadLink: <AuthDownloadLink
          url={`${downloadUrl}/${file.uuid}`}
          filename={file.name}
          onlyIcon
        />,
        name: file.name,
        deleteLink: deleteUrl ? <Button
          icon="Bin by Streamlinehq"
          onlyIcon
          onClick={() => handleDelete(file.uuid)}
        /> : null,
      }))}
    />
  )
};

export default FilesTable;